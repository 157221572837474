<template>
  <div class="quote-container">
    <JotForm :src="url" frameborder="0" />
  </div>
</template>

<script>
import JotForm from '@/components/common/JotForm.vue';

export default {
  components: {
    JotForm,
  },
  computed: {
    url() {
      return process.env.VUE_APP_CUSTOM_THEME_JOTFORMS_QUOTE_URL ?? '';
    },
  },
};
</script>

<style lang="scss" scoped>
.quote-container {
  width: 100%;
}
</style>
