<template>
  <div class="container">
    <Card class="card-width shadow-lg mt-4 mb-4">
      <template #content>
        <div class="text-center p-2">
          <h1 class="font-bold text-navy mb-4">Thank you for completing the quote form.</h1>
          <p class="text-lg text-navy mb-6">Our team will process your request and get in touch with you.</p>
          <div class="justify-center mb-6">
            <img class="icon" :src="checkmarkPath" width="80" height="80" />
          </div>
          <h2 class="font-bold text-navy mb-4">We’re here to help, if you have any questions get in touch.</h2>
          <h3 class="font-bold text-navy">Weekdays 9am - 5:30pm | {{ contactNumber }}</h3>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import Card from 'primevue/card';
import CheckmarkCHL from '@/assets/completed-check-mark-chl.svg';
import CheckmarkFinsure from '@/assets/completed-check-mark-finsure.svg';

export default {
  components: {
    Card,
  },
  data() {
    return {
      checkmarkImages: {
        chl: CheckmarkCHL,
        finsure: CheckmarkFinsure,
        bridgit: CheckmarkCHL,
      },
    };
  },
  computed: {
    originType() {
      return process.env.VUE_APP_ORIGIN_TYPE ?? 'bridgit';
    },
    checkmarkPath() {
      return this.checkmarkImages[this.originType] || this.checkmarkImages.bridgit;
    },
    contactNumber() {
      return process.env.VUE_APP_CUSTOM_THEME_CONTACT ?? '1300 141 161';
    },
  },
};
</script>

<style scoped>
.text-navy {
  color: #0c1744;
}

.container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 1rem;
}

.card-width {
  width: 100%;
  max-width: 980px;
}
</style>
